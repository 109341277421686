<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="navbar-brand">Liquidaciones</div>
    <ul class="navbar-nav">
      <li class="nav-item">
        <router-link class="nav-link" to="/dental-clinic/liquidations"
          ><i class="fa fa-check"></i> Activas
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/dental-clinic/liquidations/maked"
          ><i class=" fa fa-ban"></i> Finalizadas
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style></style>
