<template>
  <div>
    <navbar></navbar>
    <div class="mx-2">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import navbar from "./Navbar";
export default {
  components: {
    navbar
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style></style>
